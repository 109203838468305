<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Cashless Pay</div>
      <v-spacer />
      <v-btn
        flat
        color="secondary"
        :disabled="isSectionDisabled"
        @click="showRemoveDialog = true"
        v-if="value && selectedProviderType"
      >
        <v-icon class="mr-1" left>mdi-delete-outline</v-icon>remove
      </v-btn>
    </v-card-title>
    <div class="v-card-content">
      <v-select
        class="cashless-pay__select mb-2"
        label="Plan Type"
        placeholder="Choose a Plan"
        :rules="[...validateFPConfig]"
        :items="providerTypes"
        :value="selectedProviderType"
        :disabled="isSectionDisabled"
        @input="selectProvider"
      ></v-select>
      <div v-if="selectedProviderType">
        <v-text-field
          class="cashless-pay__text-field mb-2"
          label="Plan Name"
          :value="value.name"
          :rules="requiredField"
          :disabled="isSectionDisabled"
          @input="value.name = safeTrim($event)"
        />
        <v-text-field
          class="cashless-pay__text-field mb-2"
          label="Account Webview URL"
          :value="value.webview"
          :rules="[...requiredField, ...isWebViewURL]"
          :disabled="isSectionDisabled"
          @input="value.webview = safeTrim($event)"
        ></v-text-field>
        <v-text-field
          class="cashless-pay__text-field mb-2"
          label="Voucher Webview URL"
          :value="value.voucher_webview"
          :rules="[...requiredField, ...isVoucherWebViewURL]"
          :disabled="isSectionDisabled"
          @input="value.voucher_webview = safeTrim($event)"
        ></v-text-field>
        <v-text-field
          class="cashless-pay__text-field mb-2"
          label="System Key"
          ref="system_key"
          :value="value.badge_pay_system_key"
          :rules="requiredField"
          :disabled="isSectionDisabled"
          @input="value.badge_pay_system_key = safeTrim($event)"
        ></v-text-field>
        <div class="tender-list">
          <div
            class="d-flex tender"
            v-for="(tender, index) of tenders"
            :key="`brand-tender-${index}`"
          >
            <v-icon
              :style="{
                opacity: value.tenders.length > 1 ? 1 : 0,
                cursor: value.tenders.length > 1 ? 'pointer' : 'default',
              }"
              @click="removeTender(index)"
              class="mx-2 tender__remove-btn"
              large
              >mdi-close</v-icon
            >
            <v-select
              label="Tender Type"
              placeholder="Please select Tender Type"
              class="tender__select"
              :rules="requiredField"
              :value="tender.tender_type"
              :items="cashlessTenderTypes"
              @input="value.tenders[index].tender_type = $event"
            />
            <v-text-field
              label="Tender name"
              placeholder="Please insert name of Tender"
              class="tender__text-field"
              :rules="requiredField"
              :value="tender.name"
              @input="value.tenders[index].name = safeTrim($event)"
            />
            <v-select
              label="Tax Exempt"
              placeholder="Please select tax exempt"
              class="tender__select"
              :items="TAX_EXEMPT"
              :value="tender.tax_exempt"
              :rules="requiredFieldTaxExempt"
              @input="value.tenders[index].tax_exempt = $event"
            />
          </div>
          <v-btn class="add-tender-btn" flat color="primary" @click="createNewTender">
            <v-icon>mdi-plus</v-icon> <span>add another tender type</span>
          </v-btn>
        </div>
      </div>
    </div>
    <remove-dialog
      :title="'Remove Badge Pay'"
      :descriptionLines="[
        'Removing cannot be undone.',
        'To continue with removal please enter REMOVE in the field below and continue.',
      ]"
      :onConfirmHandler="remove"
      v-model="showRemoveDialog"
      :disabled="isSectionDisabled"
    />
  </v-card>
</template>

<script>
import ID from '@compassdigital/id';
import removeDialog from '@/components/removeDialog';
import { safeTrim } from '@/helpers';
import rules from '@/rules';
import { CASHLESS_TENDER_TYPES } from '@/constants';

export default {
  props: {
    value: {
      type: Object,
    },
    siteId: {
      type: String,
    },
    keystore: {
      type: Object,
    },
    isSectionDisabled: {
      type: Boolean,
      default: false,
    },
    formRef: {},
  },
  components: {
    removeDialog,
  },
  data: () => ({
    providerTypes: [{ text: 'FreedomPay', value: 'freedompay' }],
    TAX_EXEMPT: [
      {
        text: 'Yes',
        value: true,
      },
      {
        text: 'No',
        value: false,
      },
    ],
    requiredField: [rules.required('This is a required Field')],
    requiredFieldTaxExempt: [rules.required('This is a required Field', [true, false])],
    isWebViewURL: [rules.url('Valid Webview URL is required')],
    isVoucherWebViewURL: [rules.url('Valid Voucher Webview URL is required')],
    showRemoveDialog: false,
  }),
  computed: {
    tenders: {
      get() {
        return this.value?.tenders;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    selectedProviderType() {
      return this.value?.type;
    },
    getTaxExemption() {
      return this.value?.tax_exempt;
    },
    cashlessTenderTypes() {
      return CASHLESS_TENDER_TYPES;
    },
  },
  watch: {
    value(v) {
      this.$emit('input', v);
    },
    // eslint-disable-next-line func-names
    'keystore.payment.freedompay.freedompay_store_id': function() {
      this.formRef.validate();
    },
    // eslint-disable-next-line func-names
    'keystore.payment.freedompay.freedompay_terminal_id': function() {
      this.formRef.validate();
    },
    siteId() {
      if (this.value.type) {
        this.value.id = ID('payment', 'fp', 'cashless', this.siteId);
        this.$emit('input', this.value);
      }
    },
  },

  methods: {
    removeTender(indexPosition) {
      this.$set(this.value, 'tenders', [
        ...this.value.tenders.slice(0, indexPosition),
        ...this.value.tenders.slice(indexPosition + 1),
      ]);
    },
    createNewTender() {
      const cashlessTender = {
        tender_type: CASHLESS_TENDER_TYPES[0].value,
        name: '',
        tax_exempt: '',
      };
      const tenders = this.value.tenders || [];
      this.$set(this.value, 'tenders', [...tenders, cashlessTender]);
    },
    selectProvider(provider) {
      if (provider === 'freedompay' && this.value?.type !== 'freedompay') {
        this.$emit('input', {
          id: ID('payment', 'fp', 'cashless', this.siteId),
          type: provider,
          name: '',
          webview: '',
          voucher_webview: '',
          // What name?
          badge_pay_system_key: '',
          tenders: [
            {
              tender_type: CASHLESS_TENDER_TYPES[0].value,
              name: '',
              tax_exempt: '',
            },
          ],
        });
      }
    },
    setTaxExemption(tax_exempt) {
      this.value.tax_exempt = tax_exempt;
      this.$emit('input', this.value);
    },
    remove() {
      this.$emit('input', undefined);
    },
    validateFPConfig() {
      return (
        this.value?.type !== 'freedompay' ||
        Boolean(
          this.keystore?.payment?.freedompay?.freedompay_store_id &&
            this.keystore?.payment?.freedompay?.freedompay_terminal_id,
        ) ||
        Boolean(this.keystore?.payment?.data && this.keystore?.payment?.is_encrypted) ||
        'Error: Complete Payment Information to add Badge Pay'
      );
    },
    safeTrim,
  },
};
</script>

<style scoped>
/* .v-card__title {
  padding: 30px 36px 14px 24px;
} */
.cashless-pay__select:nth-of-type(1),
.cashless-pay__text-field:nth-of-type(1) {
  width: 48.16%;
  max-width: 48.16%;
}
.tender-list {
  padding: 12px 12px 48px 0;
  width: 100%;
}
.add-tender-btn {
  padding: 0;
  margin: 28px 0 0 16px;
}
.add-tender-btn ::v-deep span {
  margin-left: 24px;
}
.tender__select:not(:nth-last-child(1)),
.tender__text-field {
  margin-right: 20px;
}
.tender__select:nth-of-type(1) {
  width: 26.22%;
  max-width: 26.22%;
}
.tender__select:nth-last-child(1) {
  width: 25.6%;
  max-width: 25.6%;
}
.tender__text-field {
  width: 34.8%;
  max-width: 34.8%;
}
.tender__remove-btn {
  max-width: 36px;
}
.tender__remove-btn,
.tender__select ::v-deep * {
  cursor: pointer;
}

::v-deep input:placeholder-shown {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
